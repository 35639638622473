const config = {
    api: {
        url: 'https://api-production.boxnow.gr/api/v1',
    },
    adminApi: {
        url: 'https://api-production.boxnow.gr/ra-api',
    },
    sentry: {
        dsn: 'https://e63bb149c7544d0cb4cbeccf412f5643@o1006836.ingest.sentry.io/5980669',
    },
} as const;

export type EnvConfig = typeof config;

export default config;
